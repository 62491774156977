import $ from "jquery";

document.addEventListener("DOMContentLoaded", () => {
  const loginForm = document.querySelector('#login-form');
  const signUpForm = document.querySelector('#form-signup');

  if (loginForm) {
    formIsFilled(loginForm);
  }
  if (signUpForm) {
    formIsFilled(signUpForm);
  }

  function removeValidation(form) {
    let errors = form.querySelectorAll('.error');

    for (let i = 0; i < errors.length; i++) {
      errors[i].remove();
    }
  }

  function generateError(text) {
    let error = document.createElement('div');
    error.className = 'error';
    error.style.color = 'red';
    error.innerHTML = text;
    return error;
  }

  function checkFieldsPresence(form, fields, addrs) {
    sendAjaxData(form, addrs)
  }

  function allEventsForgotBtn(formFogotBtn){
    formFogotBtn.addEventListener('click', () => {
      let addrs = event.target.getAttribute("data-url");
      $.ajax(addrs, {
        beforeSend: function () {
          $(".popup").html('<img class="popup__loader" src="/frontend_dist/assets/img/loader.svg" alt="loader" id="loader"></img>');
          $(".popup").addClass('popup--loader');
          $("#loader").show();
        },
        success: function (content) {
          $("#loader").hide();
          $(".popup").html(content);
          $(".popup").removeClass('popup--loader');

          let form = $('#forgot')[0];

          $(".popup__btn").on('click', function (e) {
            e.preventDefault();

            let fields = form.querySelectorAll('.field');

            removeValidation(form);
            let addrs = $(this).parents('.popup__form').attr("action");
            checkFieldsPresence(form, fields, addrs);
          });

          initBtnClosePopup();

          showHidePassword();

          const forgotForm = document.querySelector('#forgot');
          if (forgotForm) {
            formIsFilled(forgotForm)
          }
        }
      })
    }, {once: true});
  }

  function sendAjaxData (form, urlAddress) {
    const post_data = $('input', form).serializeArray();

    $(".popup").html('<img class="popup__loader" src="/frontend_dist/assets/img/loader.svg" alt="loader" id="loader"></img>');
    $(".popup").addClass('popup--loader');
    $.ajax({
      method: "POST",
      data: post_data,
      url: urlAddress,
      success:  function( content, status, state ) {
        if (content && state.status == 200) {
          $(".popup").html(content);
          $(".popup").removeClass('popup--loader');
          const loginForm = document.querySelector('#login-form');
          const signUpForm = document.querySelector('#form-signup');
          const forgotForm = document.querySelector('#forgot');
          if (loginForm) {
            formIsFilled(loginForm);
          }
          if (signUpForm) {
            formIsFilled(signUpForm);
          }
          if (forgotForm) {
           const inputPassword = forgotForm.querySelector('#passwordresetrequestform-email');
            inputPassword.addEventListener('input', () => {
              const btnSend = document.querySelector('.popup__btn');
              btnSend.removeAttribute('disabled');
            })
          }
          initBtnClosePopup();
          const formFogotBtn = document.querySelector('.popup__form-forgot');
          if (formFogotBtn) {
            allEventsForgotBtn(formFogotBtn);
          }

          const btnSubmit = document.querySelector('.popup__btn');
          if(btnSubmit != null){
            btnSubmit.addEventListener('click', (e) => {
              e.preventDefault();
              let popupForm = document.querySelector(".popup__form");
              const fields = popupForm.querySelectorAll('.field');
              const form_addrs = popupForm.action;

              removeValidation(popupForm);
              checkFieldsPresence(popupForm, fields, form_addrs);
            }, {once: true});
          }

        } else {
          window.location.href = '/';
        }
      },
      error: function (error) {
        window.location.href = '/';
      }
    })
  }

  function checkFieldsPresenceLogin(form, fields, addrs) {
    let completed = true;

    fields.forEach((el) => {
      if (!el.value) {
        let error = generateError('Заполните все поля!');
        $('.error-box').html(error);
        completed = false;
        return
      }
    })

    if (completed) {
        sendAjaxData(form, addrs);
    }
  }

  let openPopupButtons = document.querySelectorAll(".open-popup"); // Кнопки для показа окна

  let popupBg = document.querySelector(".popup__bg"); // Фон попап окна
  let popup = document.querySelector(".popup"); // Само окно

  // document.addEventListener("click", (e) => {
  //   // Вешаем обработчик на весь документ
  //   if (e.target === popupBg) {
  //     // Если цель клика - фон, то:
  //     popupBg.classList.remove("active"); // Убираем активный класс с фона
  //     popup.classList.remove("active"); // И с окна
  //   }
  // });

  openPopupButtons.forEach((button) => {
    // Перебираем все кнопки
    button.addEventListener("click", (e) => {
      // Для каждой вешаем обработчик событий на клик
      e.preventDefault(); // Предотвращаем дефолтное поведение браузера

      popup.classList.add("active"); // И для самого окна
      popupBg.classList.add("active"); // Добавляем класс 'active' для фона
    });
  });

  $("[data-url]").on("click", function (e) {
    let addrs = e.target.getAttribute("data-url");

    $.ajax((addrs), {
      beforeSend: function () {
        $(".popup").html('<img class="popup__loader" src="/frontend_dist/assets/img/loader.svg" alt="loader" id="loader"></img>');
        $(".popup").addClass('popup--loader');
        $("#loader").show();
      },
      success: function (content) {
        $("#loader").hide();
        $(".popup").html(content);
        $(".popup").removeClass('popup--loader');

        let popupForm = document.querySelector(".popup__form");
        const btnSubmit = document.querySelector('.popup__btn');

        const formFogotBtn = document.querySelector('.popup__form-forgot');
        if (formFogotBtn) {
          allEventsForgotBtn(formFogotBtn);
          // formFogotBtn.addEventListener('click', (e) => {
          //   e.preventDefault();
          //   let addrs = event.target.getAttribute("data-url");
          //   $.ajax(addrs, {
          //     beforeSend: function () {
          //       $(".popup").html('<img class="popup__loader" src="/frontend_dist/assets/img/loader.svg" alt="loader" id="loader"></img>');
          //       $(".popup").addClass('popup--loader');
          //       $("#loader").show();
          //     },
          //     success: function (content) {
          //       $("#loader").hide();
          //       $(".popup").html(content);
          //       $(".popup").removeClass('popup--loader');
          //       let form = $('#forgot')[0];
          //       $(".popup__btn").on('click', function (e) {
          //         e.preventDefault();
          //         let fields = form.querySelectorAll('.field');
          //         addrs = '/site/request-password-reset';
          //         removeValidation(form);
          //         checkFieldsPresence(form, fields, addrs);
          //       })
          //       initBtnClosePopup();
          //       showHidePassword();
          //       const forgotForm = document.querySelector('#forgot');
          //       if (forgotForm) {
          //         formIsFilled(forgotForm)
          //       }
          //     }
          //   })
          // }, {once: true})
        }

        btnSubmit.addEventListener('click', () => {
          const fields = popupForm.querySelectorAll('.field');
          const form_addrs = popupForm.action;

          removeValidation(popupForm);
          checkFieldsPresence(popupForm, fields, form_addrs);
        }, {once: true});

        initBtnClosePopup();

        showHidePassword();

        const loginForm = document.querySelector('#login-form');
        const signUpForm = document.querySelector('#form-signup');

        if (loginForm) {
          formIsFilled(loginForm);
        }
        if (signUpForm) {
          formIsFilled(signUpForm);
        }
      },
    });
  });

  function initBtnClosePopup() {
    let popupClose = document.querySelector(".js-popup-close"); // Крестик

    popupClose.addEventListener('click', (e) => {
      e.preventDefault();
      popupBg.classList.remove("active"); // Убираем активный класс с фона
      popup.classList.remove("active"); // И с окна
    })
  }

  document.addEventListener("click", (e) => {
    // Вешаем обработчик на весь документ
    if (e.target.classList.contains("js-popup-hide") || e.target.classList.contains("js-popup-close")) {
      // Если цель клика - фон, то:
      popupBg.classList.remove("active"); // Убираем активный класс с фона
      popup.classList.remove("active"); // И с окна
    }
  });

  function formIsFilled(form) {
    const formFields = form.querySelectorAll('input');
    const formButton = form.querySelector('.popup__btn');
    const formCheckbox = form.querySelector('#agreement');

    form.addEventListener('input', () => {
      let isFill = true;
      formFields.forEach(item => {
        if (!item.value) isFill = false
        if (formCheckbox && !formCheckbox.checked) isFill = false;
      })

      if (isFill) {
        formButton.removeAttribute('disabled')
      } else {
        formButton.setAttribute('disabled', true)
      }
    })
  }

  function showHidePassword() {
    const passFields = document.querySelectorAll('.popup__field-pass');

    if(passFields != null){
      passFields.forEach(item => {
        const passInput = item.querySelector('input[type="password"]');
        const passToggler = item.querySelector('.pass-eye');

        passInput.addEventListener('input', function () {
          if (passInput.value !== '') {
            passToggler.style.display = 'block'
            if (passInput.value.length < 8) {
              passInput.closest('.form-group').classList.add('field--length');
            } else {
              passInput.closest('.form-group').classList.remove('field--length');
            }
          } else {
            passToggler.style.display = 'none'
          }
        })

        passToggler.addEventListener('click', function () {
          if (passInput.type === 'password') {
            passInput.setAttribute('type', 'text');
            passToggler.classList.add('active');
          } else {
            passToggler.classList.remove('active');
            passInput.setAttribute('type', 'password');
          }
        })
      })
    }
  }

  showHidePassword();
});
